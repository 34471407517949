import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { Container, Row, Col, Navbar, Nav, NavDropdown,Form,FormControl, Button,Offcanvas } from 'react-bootstrap';
//Load Styles
import styles from '../../styles/components/common/Navigation.module.scss'
// Using Global Redux State
import { useSelector, useDispatch } from 'react-redux';
//i18n - For disabling i18n option simply comment
import LanguagePopover from './LanguagePopover';
//i18n
import { useTranslation } from 'react-i18next';

function Navigation (props){
  const { t, i18n } = useTranslation();
  const [showFullNavigation, setShowFullNavigation] = useState(false);
  const [cookieBannerData, setCookieBannerData] = useState({});
  const dispatch = useDispatch()
  
  const localStorageRedux = useSelector((state) => {
   
    return state.user.localStorage
  }
  )
  

  useEffect(() => {
      if (typeof window !== 'undefined') {
       
        try {
          if(localStorageRedux?.accepted==="true")
          {       
           setShowFullNavigation(true)
          }
          // can use localStorage here
        } catch (error) {
          setShowFullNavigation(false)
        }
        
        } else {      
          // can't use localStorage
        }
      },[localStorageRedux])

  return(
     
  <React.Fragment>       
     
      <>        
      <Navbar collapseOnSelect expand="lg" className={styles.navbar}  sticky="top">
        <Container className={styles.navbarContainer}>
        <Navbar.Brand href="/">
            <Image
            className={styles.navbarLogo}
            width="278px"
            height="41px"
            //layout="fill"
            alt={`${props.brandLogoAlt}`} 
            title={`${props.brandLogoTitle}`}
            src={`${props.brandLogoUrl}`} />
        </Navbar.Brand>
        <Navbar.Toggle
        // aria-controls="responsive-navbar-nav"
        className={`order-md-1 order-0 ${styles.navbarHamburger}`}
        />
        <Navbar.Collapse id="responsive-navbar-nav" className="order-md-1 order-0">
          {showFullNavigation===true ?
          <>
          <Nav className="justify-content-end" style={{ width: "100%" }}>
          {/* <Nav style={{ width: "100%" }}> */}
            <Nav.Link href="/" className={styles.navLink}>{t('navigation_link_home')}</Nav.Link>
            <Nav.Link href="/blog" className={styles.navLink}>{t('navigation_link_blog')}</Nav.Link>
            {/* <NavDropdown title="Dropdown" className={styles.navDropdown} id="basic-nav-dropdown-one">
              <NavDropdown.Item href="#action/3.1" className={styles.navDropdownItem}>Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2" className={styles.navDropdownItem}>Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3" className={styles.navDropdownItem}>Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4" className={styles.navDropdownItem}>Separated link</NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link className={styles.navLink} href="/about">Über mich</Nav.Link>
            <Nav.Link className={styles.navLink} href="/concerts">Konzerte</Nav.Link>
            <Nav.Link className={styles.navLink} href="/music">Musik</Nav.Link>   
            <Nav.Link className={styles.navLink} href="/contact">Kontakt</Nav.Link>   
            {/* <NavDropdown title={t('navigation_link_contact')} className={styles.navDropdown} id="basic-nav-dropdown-two">
              <NavDropdown.Item href="/contact" className={styles.navDropdownItem}>{t('navigation_link_contact')}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/legal/imprint" className={styles.navDropdownItem}>{t('navigation_link_imprint')}</NavDropdown.Item>
              <NavDropdown.Item href="/legal/dataprotection" className={styles.navDropdownItem}>{t('navigation_link_dataprotection')}</NavDropdown.Item>
              <NavDropdown.Item href="/legal/agb" className={styles.navDropdownItem}>{t('navigation_link_agb')}</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          {/* <LanguagePopover/> */}
          </>
          :
            // <LanguagePopover/>
            null
          }
        </Navbar.Collapse>
        
        </Container>
      </Navbar>

      </>
     
  
  </React.Fragment>
  
)
}


export default Navigation;