import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
// import { Container, Row, Col } from 'reactstrap';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Particles from "react-tsparticles";

//Load Styles
import styles from '../../styles/components/common/Footer.module.scss'
import variables from '../../styles/variables.module.scss';

//i18n
import { useTranslation } from 'react-i18next';


//Import DummyFooterData if Backend has no data
// import footerDummyDataJson from './dummyFooterData.json';
const footerDummyDataJson = require('./dummyFooterData.json');

// Using Global Redux State
import { useSelector, useDispatch } from 'react-redux';
import { fetchWebsiteSettings } from '../../store/websiteSettingsSlice';

// The Wrapper is used to overwrite standard styles via Backend
//i.e. padding: 100px
const FooterWrapper = styled.div`
    background: ${(props)=> `url('${props.backgroundImage}')` || `url(/assets/images/footer_bg.png)`};
    background-color: ${(props)=>props.backgroundColor || `${variables.initial_footerBackgroundColor}` ||`#123456`};
    left: 0;
    bottom: 0;
    width: 100%;
    padding: ${(props)=>props.padding ? props.padding : '10px 0px 10px 0px'};
    footer {  
        padding: ${(props)=>props.padding};
        p {
            color: ${(props)=>props.pFontColor || `${variables.initial_pColor}`};
        }
        i {
            color: ${(props)=>props.iFontColor || `${variables.initial_iColor}`};
        }
    }
    border-top:  ${(props)=>props.borderTop || '5px solid black'};

`;

const LowerFooterWrapper = styled.div`
    
    background-color: ${(props)=>props.backgroundColor};
    left: 0;
    bottom: 0;
    width: 100%;
    .container {
        padding: ${(props)=>props.padding ? props.padding : '15px 0px 5px 0px'};
        a {
            color: ${(props)=>props.aFontColor} !important;            
        }
        h6 {
            color: ${(props)=>props.h6FontColor || `${variables.initial_lowerFooter_h6Color}`};
            text-align: center;
            // text-align: right;
        }
    }
`;

// This function will be triggered when the mouse pointer is over the box
var boxMouseOverHandler = function (event, color) {
    var box = event.currentTarget;
    box.style.backgroundColor = color;
};
// This function will be triggered when the mouse pointer is moving out the box
var boxMouseOutHandler = function (event, color) {
    var box = event.currentTarget;
    box.style.backgroundColor = color;
};

const socialLinkBackground = (color) =>{

    return {
        backgroundColor: `${color}`,
        width: '30px',
        height: '30px',
        borderRadius: '5px',
        position: 'relative',
    }
}

function Footer (props){
    const { t, i18n } = useTranslation();
    let language = '';
    if (typeof window !== 'undefined') {
      
        language = localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'de';
        // 👉️ can use localStorage here
      } else {

      }
   

    const dispatch = useDispatch()

    // const [footerData, setFooterData] = useState({});
    const [footerIsUsingDummyData, setFooterIsUsingDummyData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const websiteSettings = useSelector((state) => state.websiteSettings.settings)
    const footerData = websiteSettings?.Footer;
    const websiteSettingsStatus = useSelector(state => state.websiteSettings.status)

    useEffect(() => {
        if (websiteSettingsStatus === 'idle') {
          dispatch(fetchWebsiteSettings(i18n.language))
          
        }
        else if (websiteSettingsStatus === 'succeeded')
        {
            setIsLoading(false)
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [websiteSettingsStatus, dispatch])
      
      useEffect(() => {
        i18n.changeLanguage(language);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      if (isLoading===true) return null;
      
    return(
       
    <React.Fragment>       
        {
            isLoading === false ?   
        <>        
        
        <FooterWrapper 
        backgroundImage={footerData.show_background_image === true ? `${process.env.NEXT_PUBLIC_BACKEND_API_URL}${footerData.background_image?.data?.attributes.url}`:''}
        backgroundColor={footerData.background_color}
        pFontColor={footerData.p_font_color}
        iFontColor={footerData.i_font_color}
        padding={footerData.padding}
        borderTop={footerData.border_top}
        >
            <footer className={styles.footer}>
                <Container>            
                    <Row>                    
                        <Col lg="3" md="3" sm="12">
                            <div>                                          
                                <div>
                                {
                                    (footerIsUsingDummyData === false && footerData.logo.data !== null) ?
                                    <Image 
                                    width="278px"
                                    height="41px"
                                    // width={variables.initial_footerLogoWidth}
                                    // height={variables.initial_footerLogoHeight}
                                    alt={footerData.logo_alt ? `${footerData.logo_alt}`: console.log('Missing Alternative Logo')} 
                                    src={ footerData.logo.data ? `${process.env.NEXT_PUBLIC_BACKEND_API_URL}${footerData.logo.data.attributes.url}`: console.log('Missing Logo')}>
                                    </Image>:
                                    // <Image width={variables.initial_footerLogoWidth} height={variables.initial_footerLogoHeight} alt={`${footerData.logo_alt}`} src={`${footerData.logo.data.attributes.url}`}>
                                    // </Image>
                                    console.log('ERROR: Missing Logo in footer.js. Check Backend if one is set.')
                                }
                                
                                    <div>
                                    <p><i>{`${footerData.slogan ? footerData.slogan : ""}`}</i></p>
                                </div>
                                    <ul className={styles["footer-social-list"]}>
                                        { footerData.social_media?.show_facebook_icon === true ? 
                                        <li className={styles["footer-social-listitem"]}>
                                            <div className={styles.socialLinkOuter} 
                                            style={socialLinkBackground(footerData.social_media.facebook_icon_background_color)}
                                            onMouseOver={(e)=>boxMouseOverHandler(e,footerData.social_media.facebook_icon_hover_background_color ? footerData.social_media.facebook_icon_hover_background_color : variables.initial_socialLinkBgHoveringColor)}
                                            onMouseOut={(e)=>boxMouseOutHandler(e,footerData.social_media.facebook_icon_background_color)}
                                            >   
                                                <a 
                                                href={`${footerData.social_media.url_facebook}`}
                                                aria-label="External Link to Facebook"
                                                alt="External Link to Facebook"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={styles["socialLinkInner"]} 
                                            
                                                >
                                                    <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
                                                </a>
                                            </div>                                     
                                        </li>
                                        : '' }
                                        { footerData.social_media?.show_instagram_icon === true ?                                
                                        <li className={styles["footer-social-listitem"]}>
                                        <div className={styles.socialLinkOuter}
                                            style={socialLinkBackground(footerData.social_media.instagram_icon_background_color)}
                                            onMouseOver={(e)=>boxMouseOverHandler(e,footerData.social_media.instagram_icon_hover_background_color ? footerData.social_media.instagram_icon_hover_background_color : variables.initial_socialLinkBgHoveringColor)}
                                            onMouseOut={(e)=>boxMouseOutHandler(e,footerData.social_media.instagram_icon_background_color)}
                                            >                                    
                                                <a 
                                                href={ `${footerData.social_media.url_instagram}`}
                                                aria-label="External Link to Instagram"
                                                alt="External Link to Instagram"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={styles["socialLinkInner"]}>
                                                    <FontAwesomeIcon icon="fa-brands fa-instagram" />
                                                </a>
                                            </div>
                                        </li>
                                        : '' }
                                        { footerData.social_media?.show_twitter_icon === true ?                                
                                        <li className={styles["footer-social-listitem"]}>
                                        <div className={styles.socialLinkOuter}
                                            style={socialLinkBackground(footerData.social_media.twitter_icon_background_color)}
                                            onMouseOver={(e)=>boxMouseOverHandler(e,footerData.social_media.twitter_icon_hover_background_color ? footerData.social_media.twitter_icon_hover_background_color : variables.initial_socialLinkBgHoveringColor)}
                                            onMouseOut={(e)=>boxMouseOutHandler(e,footerData.social_media.twitter_icon_background_color)}
                                            >                                    
                                                <a 
                                                href={ `${footerData.social_media.url_twitter}`}
                                                aria-label="External Link to Twitter"
                                                alt="External Link to Twitter"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={styles["socialLinkInner"]}>
                                                    <FontAwesomeIcon icon="fa-brands fa-twitter" />
                                                </a>
                                            </div>
                                        </li>
                                        : '' }
                                        { footerData.social_media?.show_tiktok_icon === true ?                                
                                        <li className={styles["footer-social-listitem"]}>
                                        <div className={styles.socialLinkOuter}
                                            style={socialLinkBackground(footerData.social_media.tiktok_icon_background_color)}
                                            onMouseOver={(e)=>boxMouseOverHandler(e,footerData.social_media.tiktok_icon_hover_background_color ? footerData.social_media.tiktok_icon_hover_background_color : variables.initial_socialLinkBgHoveringColor)}
                                            onMouseOut={(e)=>boxMouseOutHandler(e,footerData.social_media.tiktok_icon_background_color)}
                                            >                                    
                                                <a 
                                                href={ `${footerData.social_media.url_tiktok}`}
                                                aria-label="External Link to TikTok"
                                                alt="External Link to TikTok"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={styles["socialLinkInner"]}>
                                                    <FontAwesomeIcon icon="fa-brands fa-tiktok" />
                                                </a>
                                            </div>
                                        </li>
                                        : '' }
                                        { footerData.social_media?.show_youtube_icon === true ?                                
                                        <li className={styles["footer-social-listitem"]}>
                                        <div className={styles.socialLinkOuter}
                                            style={socialLinkBackground(footerData.social_media.youtube_icon_background_color)}
                                            onMouseOver={(e)=>boxMouseOverHandler(e,footerData.social_media.youtube_icon_hover_background_color ? footerData.social_media.youtube_icon_hover_background_color : variables.initial_socialLinkBgHoveringColor)}
                                            onMouseOut={(e)=>boxMouseOutHandler(e,footerData.social_media.youtube_icon_background_color)}
                                            >                                    
                                                <a 
                                                href={ `${footerData.social_media.url_youtube}`}
                                                aria-label="External Link to YouTube"
                                                alt="External Link to YouTube"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={styles["socialLinkInner"]}>
                                                    <FontAwesomeIcon icon="fa-brands fa-youtube" />
                                                </a>
                                            </div>
                                        </li>
                                        : '' }
                                        { footerData.social_media?.show_spotify_icon === true ?                                
                                        <li className={styles["footer-social-listitem"]}>
                                        <div className={styles.socialLinkOuter}
                                            style={socialLinkBackground(footerData.social_media.spotify_icon_background_color)}
                                            onMouseOver={(e)=>boxMouseOverHandler(e,footerData.social_media.spotify_icon_hover_background_color ? footerData.social_media.spotify_icon_hover_background_color : variables.initial_socialLinkBgHoveringColor)}
                                            onMouseOut={(e)=>boxMouseOutHandler(e,footerData.social_media.spotify_icon_background_color)}
                                            >                                    
                                                <a 
                                                href={ `${footerData.social_media.url_spotify}`}
                                                aria-label="External Link to Spotify"
                                                alt="External Link to Spotify"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={styles["socialLinkInner"]}>
                                                    <FontAwesomeIcon icon="fa-brands fa-spotify" />
                                                </a>
                                            </div>
                                        </li>
                                        : '' }                                        
                                    </ul>
                                    <div>
                                        { footerData.contact_information ? 
                                        <ReactMarkdown className="markdown">
                                            {footerData.contact_information}
                                        </ReactMarkdown> 
                                        : ''}
                                    </div>                                
                                </div>
                            </div>
                        </Col>
                        <Col lg="3" md="3" sm="12">                        
                            <div>
                                <p>
                                {/* {t('footer_heading_next_concerts')} */}
                                </p>
                            </div>
                        </Col>                   
                        <Col lg="3" md="3" sm="12">   
                        <div>
                                <p>
                                {/* This is some content of the THIRD COL */}
                                </p>
                            </div>                
                        </Col>                    
                        <Col lg="3" md="12" sm="12" className="set-last">
                        <div>
                                <p>
                                {/* This is some content of the FOURTH COL */}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </FooterWrapper>
        
        <LowerFooterWrapper
    backgroundColor={footerData.lower_footer?.background_color || variables.initial_lowerFooter_backgroundColor}
    padding={footerData.lower_footer?.padding}
    aFontColor={footerData.lower_footer?.a_font_color || variables.initial_lowerFooter_aColor}
    h6FontColor={footerData.lower_footer?.h6_font_color}
    >
            <Container>
                <Row>
                    <Col sm="6">
                        <div className={styles["horizontal-links"]}>
                            <ul>
                                <li>                                    
                                    <Link 
                                    alt="Dataprotection Link"
                                    href="/legal/dataprotection" >
                                        <a>{t('link_dataprotection')}</a>
                                    </Link>                                  
                                </li>
                                <li>
                                    <Link href="/legal/imprint" >
                                        <a>{t('link_imprint')}</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/legal/agb" >
                                        <a>{t('link_agb')}</a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm="6" className="justify-content-end">
                        <div>
                            {/* <h6 className={styles["copyright-text"]}>{footerData.copyright ? `${footerData.copyright}` : 'No copyright text found in backend'}</h6> */}
                            <span className={styles["copyright-text"]}>{footerData.copyright ? `${footerData.copyright}` : 'No copyright text found in backend'}</span>

                        </div>
                    </Col>
                </Row>
            </Container>
        </LowerFooterWrapper>
        </>
        :' waiting for footer data'
    }
    </React.Fragment>
    
)
}

export default Footer;