// import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';
import translationEN from "../public/locales/en/translation.json";
import translationDE from "../public/locales/de/translation.json";

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    resources,
    debug: false,
    lng: 'de',
    fallbackLng: 'de',
    load: 'languageOnly',
    react: { 
      useSuspense: true
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;