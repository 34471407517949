import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit'

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('cookieBannerData');
    if (serializedState === null) {      
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {    
    return undefined;
  }
}; 

const persistedState = loadState();
const initialState = {
  settings: {},
  localStorage: persistedState,
  musicData: [],
  impressionsData: [],
  status: 'idle',
  error: null
}

// export const fetchUserSettings = createAsyncThunk('websiteSettings/fetchWebsiteSettings', async (selectedLanguage) => {
//   const response = await fetch(`/api/backend/user?locale=${selectedLanguage}`, {
//     headers: {
//       Accept: "application/json",
//     },
//   });
//   const settingsDataFromBackend = await response.json();
//   return settingsDataFromBackend
// })


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
      getLocalUserInfo: (state, action) => {          
          state.localStorage = { data: "stringus90" };
      },
      updateLocalStorage: (state, action) => {                  
        state.localStorage = action.payload;
      },
      setMusicData: (state, action) => {
        state.musicData = action.payload;
      },
      setImpressionsData: (state, action) => {
        state.impressionsData = action.payload;
      },
  },
  // extraReducers(builder) {
  //   builder
  //     .addCase(fetchUserSettings.pending, (state, action) => {
  //       state.status = 'loading'
  //     })
  //     .addCase(fetchUserSettings.fulfilled, (state, action) => {
  //       state.status = 'succeeded'
  //       state.settings = action.payload
  //     })
  //     .addCase(fetchUserSettings.rejected, (state, action) => {
  //       state.status = 'failed'
  //       state.error = action.error.message
  //     })
  // }
})

export const { getLocalUserInfo,updateLocalStorage,setMusicData, setImpressionsData  } = userSlice.actions

export const selectMusicData = (state) => state.user.musicData;

export const selectImpressionsData = (state) => state.user.impressionsData;

export default userSlice.reducer