import '../styles/globals.scss'
import NextNProgress from "nextjs-progressbar";
import Router from 'next/router';
import { useState,useEffect } from 'react';
import { useRouter } from 'next/router'
import NProgress from 'nprogress'; //nprogress module
import 'nprogress/nprogress.css'; //styles of nprogress
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faEnvelope, faLink,faTicket,faCheck,faX } from '@fortawesome/free-solid-svg-icons'
// import { faTicket } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram, faFacebook,faFacebookF,faPinterest,faTiktok,faSpotify,faYoutube,faWhatsapp } from '@fortawesome/free-brands-svg-icons'
library.add(fas, faTwitter, faInstagram, faFacebook,faFacebookF,faPinterest,faEnvelope,faLink,faTiktok,faSpotify,faYoutube,faWhatsapp,faTicket,faCheck,faX)
// import i18n (needs to be bundled ;)) 
import '../i18n/i18n';
import { wrapper } from "../store/";
import Maintainance from '../components/common/Maintainance';


function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const [pathName, setPathName] = useState(null);
 
  useEffect(() => {
    
    // Redirect to /maintainance page
   
    if (process.env.NEXT_PUBLIC_MAINTAINANCE_MODE==="1") {  
      if (pathName && typeof window !== 'undefined') {
        router.push("/maintainance");  
      }
       
    }
    setPathName(router.pathname)
  }, [pathName,router]);
  //}, [pathName]);

  useEffect(() => {
    // Store the current pathname
    setPathName(router.pathname);
  }, [router.pathname]);

  return (
  <>
    <Component {...pageProps} />
  </>
  )
}


export default wrapper.withRedux(MyApp);